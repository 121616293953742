import React, {useRef, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputMask from 'react-input-mask';
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import {Container, Spinner} from "react-bootstrap";
import emailjs from '@emailjs/browser';
import {toast} from "react-toastify";
import {isCNPJ, isCPF} from 'brazilian-values';

function EnrollForm() {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    let isEmailValid = validateEmail(form);
    let isDocumentValid = validateDocument(form.document[0].value);
    let isPhoneValid = validatePhone(form.phone.value);
    if (form.checkValidity() === false || !isEmailValid || !isDocumentValid || !isPhoneValid) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      sendEmail(event);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    return emailjs
      .sendForm(
        'service_ktuor1i', 'template_bfkh6ih', form.current, '57QbxsmAbWUnniJb1'
      )
      .then(
        (result) => {
          cleanUpForm();
          toast.success('Mensagem enviada com sucesso!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          setIsLoading(false);
          setValidated(false);
        },
        (error) => {
          toast.error('Um erro inesperado ocorreu. Tente novamente mais tarde, ou entre em contato pelo e-mail ao lado.', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          setIsLoading(false);
          setValidated(false);
        }
      );
  }

  function cleanUpForm() {
    form.current.reset();
    setDocument('');
    setDocumentError('');
    setPhone('');
    setPhoneError('');
  }

  /**
   * Email validation
   */
  const [emailError, setEmailError] = useState('');

  function validateEmail(form) {
    let isValid = true;
    if (form.email.validity.valueMissing) {
      setEmailError('Campo obrigatório.');
      isValid = false;
    }
    if (form.email.validity.typeMismatch) {
      setEmailError('Por favor, insira um e-mail válido.');
      isValid = false;
    }
    return isValid;
  }

  /**
   * CPF and CNPJ validation
   */
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [documentError, setDocumentError] = useState('');
  const [employeesNumber, setEmployeesNumber] = useState('');

  const handleDocumentChange = (event) => {
    const docValue = event.target.value;
    setDocument(docValue);
    validateDocument(docValue);
  };

  const handleTypeChange = (event) => {
    setDocumentType(event.target.value);
    setDocument('');
    setDocumentError('');
    setEmployeesNumber('');
  };

  function validateDocument(docValue) {
    let isValid = true;
    if (docValue.replace(/\D/g, '').length === 0) {
      setDocumentError('Campo obrigatório.');
      isValid = false;
    } else if (documentType === 'CPF' && !isCPF(docValue)) {
      setDocumentError('Por favor, insira um CPF válido.');
      isValid = false;
    } else if (documentType === 'CNPJ' && !isCNPJ(docValue)) {
      setDocumentError('Por favor, insira um CNPJ válido.');
      isValid = false;
    } else {
      setDocumentError('');
    }
    return isValid;
  }

  /**
   * Phone number validation
   */
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handlePhoneChange = (event) => {
    const phoneValue = event.target.value;
    setPhone(phoneValue);
    validatePhone(phoneValue);
  };

  function validatePhone(phone) {
    let isValid = true;
    if (phone === '' || phone.replace(/\D/g, '').length === 0) {
      setPhoneError('Campo obrigatório.');
      isValid = false;
    } else if (phone.replace(/\D/g, '').length < 10) {
      setPhoneError('Por favor, insira um telefone válido.');
      isValid = false;
    } else {
      setPhoneError('');
    }
    return isValid;
  }

  return (
    <Container className="form-wrapper">
      <Form noValidate validated={validated} ref={form} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="name" className="form-group">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder="Nome / Razão Social"
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="email" className="form-group">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              name="email"
              type="email"
              placeholder="E-mail"
            />
            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3" controlId="city" className="form-group">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              required
              name="city"
              type="text"
              placeholder="Cidade"
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="state" className="form-group">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              required
              name="state"
              type="text"
              placeholder="Estado"
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="phone" className="form-group">
            <Form.Label>Telefone</Form.Label>
            <TelefoneBrasileiroInput
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              className={`form-control ${phoneError ? 'is-invalid' : ''}`}
              placeholder="(DDD) 12345-6789"
              temDDD
              separaDDD
            />
            {phoneError && (
              <div className="invalid-feedback" style={{display: 'block'}}>
                {phoneError}
              </div>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="document" className="form-group">
            <Form.Label>Documento</Form.Label>
            <InputMask
              id="value"
              mask={documentType === 'CPF' ? "999.999.999-99" : "99.999.999/9999-99"}
              value={document}
              onChange={handleDocumentChange}
              required
              name="document"
              type="text"
              placeholder={documentType === 'CPF' ? "CPF" : "CNPJ"}
              className={`form-control ${documentError ? 'is-invalid' : ''}`}
            />
            {documentError && (
              <div className="invalid-feedback" style={{display: 'block'}}>
                {documentError}
              </div>
            )}
            <div style={{marginTop: 10}}>
              <Form.Check
                inline
                type="radio"
                label="CPF"
                name="documentType"
                value="CPF"
                checked={documentType === 'CPF'}
                onChange={handleTypeChange}
              />
              <Form.Check
                inline
                type="radio"
                label="CNPJ"
                name="documentType"
                value="CNPJ"
                checked={documentType === 'CNPJ'}
                onChange={handleTypeChange}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="employeesNumber" className="form-group" hidden={documentType === 'CPF'}>
            <Form.Label>Número de Colaboradores</Form.Label>
            <Form.Control
              required={documentType === 'CNPJ'}
              value={employeesNumber}
              name="employeesNumber"
              type="number"
              placeholder="Total contando sócios"
              min={0}
              onChange={(e) => setEmployeesNumber(e.target.value)} // Update state on change
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group as={Col} md="12" controlId="message" className="form-group mb-3">
          <Form.Control
            required
            rows="4"
            name="message"
            as="textarea"
            placeholder="Mensagem"
          />
          <Form.Control.Feedback type="invalid">
            Campo obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
        <button type="submit" className="btn-custom" disabled={isLoading}>
          Enviar
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
            hidden={!isLoading}
          />
        </button>
      </Form>
    </Container>
  );
}

export default EnrollForm;
