import {Element, Link} from 'react-scroll';

export const Header = (props) => {
  return (
      <header id='header'>
        <div id="header-anchor" className="anchor"></div>
        <div className='header'>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 offset-md-2 header-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  <Link to="intro" spy={true} smooth={true}
                        duration={150} offset={-125}
                        className='btn-custom' href="#">Saiba mais</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}
