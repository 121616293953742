import {useEffect, useState} from "react";
import {EnrollContact} from "./enroll-contact";
import JsonData from "../../../data/enroll.json";
import {scrollSpy} from "react-scroll";
import "./enroll.scss"

export const Enroll = (props) => {
  const [enrollData, setEnrollData] = useState({});
  useEffect(() => {
    setEnrollData(JsonData);
  }, []);

  useEffect(() => {
    scrollSpy.update()
    window.addEventListener('resize', scrollSpy.update)
    return () => {
      window.removeEventListener('resize', scrollSpy.update)
    }
  }, []);

  useEffect(() => {
    const buttonElement = document.getElementById('whatsAppButton');
    if (buttonElement) {
      buttonElement.style.display = 'unset';
    }
  }, []);

  return (
    <div id="enroll">
      <EnrollContact data={enrollData.Contact}/>
    </div>
  );
}

export default Enroll;
