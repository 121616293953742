import React, {useEffect, useRef} from "react";
import {Link, NavLink} from "react-router-dom";
import {Collapse} from "bootstrap";

function MainMenu() {
  function _activate(e) {
    _cleanupActiveMenuItems();
    let currentParent = e.currentTarget.parentElement;
    if(currentParent.nodeName === 'LI') {
     currentParent.classList.add('main-active');
    }
  }

  function _cleanupActiveMenuItems() {
    let mainMenu = document.getElementById('main-menu-collapse').children.item(0).children;
    Array.from(mainMenu).forEach(function (item) {
      if (item.nodeName === 'LI') {
        item.classList.remove('main-active');
      }
    });
  }

  /*
   * Effect responsible for closing the collapsible menu after the user chooses an option.
   * This has effect only in case the page is smaller than 992px.
   */
  const collapseRef = useRef(null);
  useEffect(() => {
    const navLinks = document.querySelectorAll('.main-menu-button');
    const menuCollapse = document.getElementById('main-menu-collapse');
    collapseRef.current = new Collapse(menuCollapse);
    navLinks.forEach((l) => {
      l.addEventListener('click', (e) => {
        if (document.children[0].clientWidth < 992) {
          if (collapseRef.current._isShown()) {
            collapseRef.current.hide();
          } else {
            collapseRef.current.show();
          }
        }
      })
    })
    const menuToggle = document.querySelector('#menu-toggle');
    menuToggle.addEventListener('click', (e) => {
      if (document.children[0].clientWidth < 992) {
        if (collapseRef.current._isShown()) {
          collapseRef.current.hide();
        } else {
          collapseRef.current.show();
        }
      }
    })
    return () => {};
  }, []);

  /*
   * Effect responsible for closing the collapsible menu at first loading, so the page starts with it in the closed state.
   * This has effect only in case the page is smaller than 992px.
   */
  useEffect(() => {
    const checkTransition = () => {
      if (collapseRef.current && !collapseRef.current._isTransitioning && document.children[0].clientWidth < 992) {
        collapseRef.current.hide();
        clearInterval(interval);
      }
    };
    const interval = setInterval(checkTransition, 100); // Check every 100ms

    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, []);

  return (
    <nav id='main-menu' className='navbar navbar-default navbar-expand-lg fixed-top'>
      <div className='container'>
        <div className='navbar-header header-logo me-auto d-inline-flex'>
          <img src="/assets/img/abcarbon_logo.png" className="img-responsive" />
          <Link to="/" className='navbar-brand' onClick={_activate}>abcarbon</Link>
        </div>
        <button
          id="menu-toggle" type="button" className="navbar-toggler ms-auto"
          aria-controls="main-menu-collapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          id='main-menu-collapse'
          className='collapse navbar-collapse'
        >
          <ul className='navbar-nav ms-auto'>
            <div className="spacing"></div>
            <li>
              <NavLink to="/" className='main-menu-button section-button page-scroll'
                       onClick={_activate}>Início</NavLink>
            </li>
            <div className="spacing"></div>
            <li>
              <NavLink to="/certificados" className='main-menu-button section-button page-scroll'
                       onClick={_activate}>Certificados</NavLink>
            </li>
            <div className="spacing"></div>
            <li>
              <NavLink to="/conferencia" className='main-menu-button section-button page-scroll'
                       onClick={_activate}>Conferência</NavLink>
            </li>
            <div className="spacing"></div>
            <li>
              <a href='/noticias' className='main-menu-button section-button page-scroll' target="_blank">
                Notícias
              </a>
            </li>
            <div className="spacing"></div>
            <div className="spacing"></div>
            <li>
              <NavLink to="/associe-se" className='main-menu-button btn-custom enroll-button'
                       onClick={_activate}>Associe-se</NavLink>
            </li>
            <div className="spacing"></div>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default MainMenu;
