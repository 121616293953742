import {useEffect, useState} from "react";
import {collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter} from "firebase/firestore";
import {db} from "../../../config/firebase";
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import {formatDocId, formatTransactionDate} from "../../../utils/utils";

const columns = [
  {id: "userKey", label: "Usuário", minWidth: 170, align: "center"},
  {id: "creationCode", label: "Código de Criação", minWidth: 100, align: "center"},
  {id: "baseYear", label: "Ano Base", minWidth: 170, align: "center"},
  {
    id: "carbonCredit",
    label: "Créditos de Carbono",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("pt-BR"),
  },
  {
    id: "status",
    label: "Situação",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const ROWS_PER_PAGE = 10;

export default function CertificationsList() {
  const [certifications, setCertifications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [totalCertifications, setTotalCertifications] = useState(0);

  function extractTableData(certificationsSnapshot) {
    return certificationsSnapshot.docs.map(
      (doc) =>
        ({
          baseYear: `${doc.data().baseYear.startDate.toDate().toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          })} - ${doc.data().baseYear.endDate.toDate().toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          })}`,
          date: doc.data()?.date.toDate(),
          dateString: doc.data()?.date.toDate().toLocaleDateString(),
          userKey: doc.data().userKey,
          creationCode: doc.data().creationCode,
          carbonCredit: doc.data().carbonCredit,
          status: doc.data().status,
          address: {
            state: doc.data().address.state,
            country: doc.data().address.country
          },
          transactions: doc.data().transactions
        })).sort((a, b) => b.date - a.date);
  }

  useEffect(() => {
    let isMounted = true; // set up a variable to track whether the component is mounted or not

    const fetchTotalCertifications = async () => {
      const certificationsRef = collection(db, "certifications");
      const snapshot = await getCountFromServer(certificationsRef);
      if (isMounted) {
        setTotalCertifications(snapshot.data().count);
      }
    };

    const fetchCertifications = async () => {
      const certificationsRef = collection(db, "certifications");
      const certificationsQuery = query(certificationsRef, orderBy("creationCode"), limit(rowsPerPage));
      const certificationsSnapshot = await getDocs(certificationsQuery);
      const certificationsData = extractTableData(certificationsSnapshot);
      if (isMounted) {
        setCertifications(certificationsData);
        setLastVisibleDoc(certificationsSnapshot.docs[certificationsSnapshot.docs.length - 1]);
      }
    };

    fetchTotalCertifications();
    fetchCertifications();

    // Return a cleanup function to cancel any subscriptions or async tasks
    return () => {
      isMounted = false;
    };
  }, [rowsPerPage]);

  const handleChangePage = async (event, newPage) => {
    if (newPage > page) {
      if (!lastVisibleDoc) return; // Ensure lastVisibleDoc is defined
      // Fetch the next page of data
      const certificationsRef = collection(db, "certifications");
      const certificationsQuery = query(
        certificationsRef,
        orderBy("creationCode"),
        startAfter(lastVisibleDoc),
        limit(rowsPerPage)
      );
      const certificationsSnapshot = await getDocs(certificationsQuery);
      const certificationsData = extractTableData(certificationsSnapshot);

      if (certificationsSnapshot.docs.length > 0) {
        setCertifications(certifications.concat(certificationsData));
        setLastVisibleDoc(certificationsSnapshot.docs[certificationsSnapshot.docs.length - 1]);
      }
    } else {
      // Fetch the previous pages of data
      const certificationsRef = collection(db, "certifications");
      const certificationsQuery = query(certificationsRef, orderBy("creationCode"), limit((newPage + 1) * rowsPerPage));
      const certificationsSnapshot = await getDocs(certificationsQuery);
      const certificationsData = extractTableData(certificationsSnapshot);

      setCertifications(certificationsData);
      setLastVisibleDoc(certificationsSnapshot.docs[certificationsSnapshot.docs.length - 1]);
    }

    setPage(newPage);
  };


  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    // Fetch the first page of data with the new rowsPerPage value
    const certificationsRef = collection(db, "certifications");
    const certificationsQuery = query(certificationsRef, orderBy("creationCode"), limit(newRowsPerPage));
    const certificationsSnapshot = await getDocs(certificationsQuery);
    const certificationsData = extractTableData(certificationsSnapshot);
    setCertifications(certificationsData);
    setLastVisibleDoc(certificationsSnapshot.docs[certificationsSnapshot.docs.length - 1]);
  };

  /**
   * Details Dialog
   */
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const renderCertificationDetails = (row) => {
    // Calculate total offset from all "COMPENSATION" transactions
    const totalOffset = row.transactions
      .filter(transaction => transaction.type === "COMPENSATION")
      .reduce((sum, transaction) => sum + parseFloat(transaction.offset), 0);
    return (
      <div>
        <Typography><strong>Ano Base:</strong> {row.baseYear}</Typography>
        <Typography><strong>Localidade:</strong> {row.address.state} / {row.address.country}</Typography>
        <Typography><strong>Créditos:</strong> {row.carbonCredit.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography><strong>Créditos Compensados:</strong> {totalOffset.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography><strong>Situação:</strong> {row.status}</Typography>
        <Typography><strong>Usuário:</strong> {row.userKey}</Typography>
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>TRANSAÇÕES</Typography>
        {row.transactions.map(renderTransactionCard)}
      </div>
    );
  }

  const renderTransactionCard = (transaction) => {
    const cardStyles = {
      marginBottom: 2,
      backgroundColor: "rgba(241,241,241,0.42)",
      //backgroundColor: "rgba(92,167,251,0.5)",
      //backgroundColor: transaction.type === "CREATION" ? "rgba(190,246,255,0.5)" : transaction.type === "CUSTODY" ? "rgba(255,236,179,0.5)" : "rgba(200,230,201,0.5)", // Different colors for each type
    };

    switch (transaction.type) {
      case "CREATION":
        return (
          <Card key={transaction.key} sx={cardStyles}>
            <CardContent>
              <Typography><strong>Criação do Certificado</strong></Typography>
              <Typography><strong>Data:</strong> {formatTransactionDate(transaction.date)}</Typography>
              <Typography><strong>Chave:</strong> {transaction.key}</Typography>
              <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
            </CardContent>
          </Card>
        );
      case "CUSTODY":
        return (
          <Card key={transaction.key} sx={cardStyles}>
            <CardContent>
              <Typography><strong>Custódia</strong></Typography>
              <Typography><strong>Data:</strong> {formatTransactionDate(transaction.date)}</Typography>
              <Typography><strong>Chave:</strong> {transaction.key}</Typography>
              <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
            </CardContent>
          </Card>
        );
      case "COMPENSATION":
        return (
          <Card key={transaction.key} sx={cardStyles}>
            <CardContent>
              <Typography><strong>Compensação</strong></Typography>
              <Typography><strong>Data:</strong> {formatTransactionDate(transaction.date)}</Typography>
              <Typography><strong>Chave:</strong> {transaction.key}</Typography>
              <Typography><strong>Quantidade:</strong> {transaction.offset} CCO2</Typography>
              <Typography><strong>Nome:</strong> {transaction.buyer.name}</Typography>
              <Typography><strong>{transaction.buyer.docType}:</strong> {formatDocId(transaction.buyer.docType, transaction.buyer.docId)}</Typography>
              <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
            </CardContent>
          </Card>
        );
      default:
        return null;
    }
  };


  return (
    <Paper sx={{width: "100%", overflow: "hidden"}} elevation={2} className="background-transp">
      <TableContainer sx={{maxHeight: 590}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="actions" align="center" style={{minWidth: 50}}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.creationCode}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Tooltip title="Detalhes do Certificado">
                    <IconButton color="gray" onClick={() => handleClickOpen(row)}>
                      <InfoIcon/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component="div"
        count={totalCertifications}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Resultados por página:"}
      />
      <Dialog open={open} onClose={handleClose} PaperProps={{sx: {borderRadius: 4}}}>
        <DialogTitle>Detalhes do Certificado</DialogTitle>
        <DialogContent>{selectedRow && renderCertificationDetails(selectedRow)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
