import {Nav} from "react-bootstrap";
import { Link } from 'react-scroll';

export const HomeMenu = (props) => {
  return (
    <nav id='home-menu' className='menu-scroll navbar navbar-default navbar-expand-md fixed-top'>
      <div className='container d-flex justify-content-center'>
        <Nav as="ul" className='navbar-nav'>
          <Nav.Item as="li">
            <Link to="header" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Início</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="intro"  spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Introdução</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="about" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Sobre nós</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="credits" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Créditos</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="learn-more" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Saiba mais</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="contact" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Contato</Link>
          </Nav.Item>
        </Nav>
      </div>
    </nav>
  )
}
