export const Themes = (props) => {
  return (
    <div id="themes" className='text-center'>
      <div id="themes-anchor" className="anchor"></div>
      <div className="container">
        <h2>{props.data ? props.data.title : "loading..."}</h2>
        <div className="row pt-4">
          <div className="card-wrapper col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h3>{props.data ? props.data.esg.title : 'loading...'}</h3>
                <p>{props.data ? props.data.esg.item1 : 'loading...'}</p>
                <p>{props.data ? props.data.esg.item2 : 'loading...'}</p>
                <p>{props.data ? props.data.esg.item3 : 'loading...'}</p>
                <p>{props.data ? props.data.esg.item4 : 'loading...'}</p>
                <p>{props.data ? props.data.esg.item5 : 'loading...'}</p>
              </div>
            </div>
          </div>
          <div className="card-wrapper  col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h3>{props.data ? props.data.market.title : 'loading...'}</h3>
                <p>{props.data ? props.data.market.item1 : 'loading...'}</p>
                <p>{props.data ? props.data.market.item2 : 'loading...'}</p>
                <p>{props.data ? props.data.market.item3 : 'loading...'}</p>
                <p>{props.data ? props.data.market.item4 : 'loading...'}</p>
                <p>{props.data ? props.data.market.item5 : 'loading...'}</p>
              </div>
            </div>
          </div>
          <div className="card-wrapper col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h3>{props.data ? props.data.laws.title : 'loading...'}</h3>
                <p>{props.data ? props.data.laws.item1 : 'loading...'}</p>
                <p>{props.data ? props.data.laws.item2 : 'loading...'}</p>
                <p>{props.data ? props.data.laws.item3 : 'loading...'}</p>
                <p>{props.data ? props.data.laws.item4 : 'loading...'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
