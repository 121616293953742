export const Footer = (props) => {
  return (
    <div id='footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 offset-md-2 header-text'>
            <h1>
              {props.data ? props.data.title : 'Loading'}
              <span></span>
            </h1>
            <h3>{props.data ? props.data.subtitle1 : 'Loading'}</h3>
            <p>{props.data ? props.data.subtitle2 : 'Loading'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
