import {Link} from 'react-scroll';

export const Header = (props) => {
  return (
      <header id='header'>
        <div id="header-anchor" className="anchor"></div>
        <div className='header'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 offset-md-2 header-text'>
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <h3>{props.data ? props.data.subtitle : 'Loading'}</h3>
                  <p className="info">{props.data ? props.data.date : 'Loading'}</p>
                  <p className="info">{props.data ? props.data.location1 : 'Loading'}</p>
                  <p className="info">{props.data ? props.data.location2 : 'Loading'}</p>
                  <p className="info">{props.data ? props.data.location3 : 'Loading'}</p>
                  <div className="p-4"></div>
                  <Link to="intro" spy={true} smooth={true}
                        duration={150} offset={-125}
                        className='btn-custom' href="#">Saiba mais</Link>
                  <a id="ticketBtn" className='btn-custom' target="_blank"
                     href="https://www.sympla.com.br/evento/abcarbon-conference/1992217">
                    Ingressos <i className="fa fa-external-link"></i>
                  </a>
                </div>
              </div>
            </div>
        </div>
      </header>
  )
}
