import {Nav} from "react-bootstrap";
import {Link} from 'react-scroll';

export const ConferenceMenu = (props) => {
  return (
    <nav id='conference-menu' className='menu-scroll navbar navbar-default navbar-expand-md fixed-top'>
      <div className='container d-flex justify-content-center'>
        <Nav as="ul" className='navbar-nav'>
          <Nav.Item as="li">
            <Link to="header" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Início</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="intro"  spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Introdução</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="themes" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Temáticas</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="leadership" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Lideranças</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="target" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Público Alvo</Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="attendance" spy={true} smooth={true}
                  duration={150} offset={-125}
                  className="section-button" href="#">Presenças</Link>
          </Nav.Item>
          {/*<Nav.Item as="li">*/}
          {/*  <Link to="map" spy={true} smooth={true}*/}
          {/*        duration={150} offset={-125}*/}
          {/*        className="section-button" href="#">Mapa</Link>*/}
          {/*</Nav.Item>*/}
        </Nav>
      </div>
    </nav>
  )
}
