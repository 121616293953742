// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNHpwQPXg7DG3agwcRjHWbjmNMjJswyz0",
  authDomain: "abcarbon-app.firebaseapp.com",
  projectId: "abcarbon-app",
  storageBucket: "abcarbon-app.appspot.com",
  messagingSenderId: "882696907339",
  appId: "1:882696907339:web:653cf7990b2b191c7dfbce",
  measurementId: "G-EK1NH417F2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const apiFetchCompensations = httpsCallable(functions, 'api/compensations');
