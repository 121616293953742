export const formatDocId = (docType, docId) => {
  if (docType === "CNPJ") {
    return docId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  } else if (docType === "CPF") {
    return docId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  }
  return docId;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'});
};

export const formatTransactionDate = (date) => {
  const dateObj = new Date(date.seconds * 1000);
  return dateObj.toLocaleDateString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric'});
};
