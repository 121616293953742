export const Itinerary = (props) => {
  return (
      <div id="itinerary" className="text-center">
        <div className="container">
          <h2>{props.data ? props.data.title : 'Loading'}</h2>
          <div className="section-paragraph">
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <p>
              {props.data ? props.data.time : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.date : 'Loading'}
            </p>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            <p>
              {props.data ? props.data.location1 : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.location2 : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.location3 : 'Loading'}
            </p>
            <div id="buttonWrapper">
              <a id="ticketBtn" className='btn-custom' target="_blank"
                 href="https://www.sympla.com.br/evento/abcarbon-conference/1992217">
                Ingressos <i className="fa fa-external-link"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
  );
};
