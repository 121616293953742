import {useEffect, useState} from "react";
import {HomeMenu} from "./home-menu";
import {Header} from "./header";
import {Intro} from "./intro";
import {About} from "./about";
import {Credits} from "./credits";
import {LearnMore} from "./learn-more";
import {Contact} from "./contact";
import JsonData from "../../../data/home.json";
import {scrollSpy} from "react-scroll";
import "./home.scss"

export const Home = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    scrollSpy.update()
    window.addEventListener('resize', scrollSpy.update)
    return () => {
      window.removeEventListener('resize', scrollSpy.update)
    }
  }, []);

  useEffect(() => {
    const buttonElement = document.getElementById('whatsAppButton');
    if (buttonElement) {
      buttonElement.style.display = 'unset';
    }
  }, []);

  return (
    <div id="home">
      <HomeMenu/>
      <Header data={landingPageData.Header}/>
      <Intro data={landingPageData.Intro}/>
      <About data={landingPageData.About}/>
      <Credits data={landingPageData.Credits}/>
      <LearnMore data={landingPageData.LearnMore}/>
      {/*<Associates data={landingPageData.Associates}></Associates>*/}
      <Contact data={landingPageData.Contact}/>
    </div>
  );
}

export default Home;
