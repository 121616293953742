export const Target = (props) => {
  return (
    <div id="target">
      <div id="about-anchor" className="anchor"></div>
      <div className="container d-none d-md-block">
        <h2>{props.data ? props.data.title : "loading..."}</h2>
          <div className="row">
          <div className="col-6">
            <div className="target-text">
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph1 : "loading..."}</h3>
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph2 : "loading..."}</h3>
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph3 : "loading..."}</h3>
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph4 : "loading..."}</h3>
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph5 : "loading..."}</h3>
              <h3><i className="fa fa-leaf"></i> {props.data ? props.data.paragraph6 : "loading..."}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-block d-md-none text-center">
        <h2>{props.data ? props.data.title : "loading..."}</h2>
        <div className="row">
          <div className="col-12">
            <div className="target-text">
              <h3>{props.data ? props.data.paragraph1 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph2 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph3 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph4 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph5 : "loading..."}</h3>
              <h3>{props.data ? props.data.paragraph6 : "loading..."}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
