export const Intro = (props) => {
  return (
      <div id="intro" className="text-center">
        <div id="intro-anchor" className="anchor"></div>
        <div className="container">
          <h2>{props.data ? props.data.title : 'Loading'}</h2>
          <div className="section-paragraph">
            <p>
              {props.data ? props.data.paragraph1 : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.paragraph2 : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.paragraph3 : 'Loading'}
            </p>
            <p>
              {props.data ? props.data.paragraph4 : 'Loading'}
            </p>
          </div>
        </div>
      </div>
  );
};
