import React, {useCallback, useRef, useState} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {make3dTransformValue} from "react-quick-pinch-zoom";
import {toast, ToastContainer} from "react-toastify";
import InputMask from "react-input-mask";
import {isCNPJ, isCPF} from "brazilian-values";
import "./search-form.scss";
import {apiFetchCompensations} from "../../../config/firebase";
import {Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {formatDate, formatDocId} from "../../../utils/utils";

function CompensationSearchForm() {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();
  const imgRef = useRef();
  const onUpdate = useCallback(({x, y, scale}) => {
    const {current: img} = imgRef;

    if (img) {
      const value = make3dTransformValue({x, y, scale});
      img.style.setProperty("transform", value);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isDocumentValid = validateDocument(form.documentInput.value);
    if (form.checkValidity() === false || !isDocumentValid) {
      setValidated(true);
    } else {
      const docType = form.documentType.value;
      const docId = form.documentInput.value.replace(/\D/g, ''); // Remove non-digit characters
      fetchCompensations(docType, docId);
    }
  };

  async function fetchCompensations(docType, docId) {
    setIsLoading(true);
    try {
      const response = await apiFetchCompensations({ docType, docId });
      setCompensations(response.data);
      setOpenDialog(true);
    } catch (error) {
      if (error.code === 'functions/not-found') {
        toast.error('Nenhuma compensação encontrada para o documento fornecido.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else {
        toast.error('Erro ao buscar certificado.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } finally {
      setIsLoading(false);
      setValidated(false);
    }
  }

  /**
   * CPF and CNPJ validation
   */
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [documentError, setDocumentError] = useState('');
  const [documentTouched, setDocumentTouched] = useState(false);

  const handleDocumentChange = (event) => {
    const docValue = event.target.value;
    setDocument(docValue);
    setDocumentTouched(true);
    validateDocument(docValue);
  };

  const handleTypeChange = (event) => {
    setDocumentType(event.target.value);
    setDocument('');
    setDocumentError('');
    setDocumentTouched(false);
  };

  function validateDocument(docValue) {
    let isValid = true;
    if (docValue.replace(/\D/g, '').length === 0) {
      setDocumentError('Campo obrigatório.');
      isValid = false;
    } else if (documentType === 'CPF' && !isCPF(docValue)) {
      setDocumentError('Por favor, insira um CPF válido.');
      isValid = false;
    } else if (documentType === 'CNPJ' && !isCNPJ(docValue)) {
      setDocumentError('Por favor, insira um CNPJ válido.');
      isValid = false;
    } else {
      setDocumentError('');
    }
    return isValid;
  }

  /**
   * Details Dialog
   */
  const [openDialog, setOpenDialog] = useState(false);
  const [compensations, setCompensations] = useState(null);

  const handleClose = () => {
    setOpenDialog(false);
    setCompensations(null);
  };

  const renderCertificationDetails = (compensations) => {
    // Calculate total offset from all "COMPENSATION" transactions
    const totalOffset = compensations.reduce((sum, transaction) => sum + parseFloat(transaction.offset), 0);
    const name = compensations[0]?.buyer.name;

    return (
      <div>
        <Typography><strong>{documentType}:</strong> {formatDocId(documentType, document)}</Typography>
        <Typography><strong>Nome:</strong> {name}</Typography>
        <Typography><strong>Total de Créditos Compensados:</strong> {totalOffset.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>TRANSAÇÕES</Typography>
        {compensations.map(renderTransactionCard)}
      </div>
    );
  }
  const renderTransactionCard = (transaction) => {
    const cardStyles = {
      marginBottom: 2,
      backgroundColor: "rgba(241,241,241,0.42)",
    };

    return (
      <Card key={transaction.key} sx={cardStyles}>
        <CardContent>
          <Typography><strong>Data:</strong> {formatDate(transaction.date)}</Typography>
          <Typography><strong>Chave:</strong> {transaction.key}</Typography>
          <Typography><strong>Quantidade:</strong> {transaction.offset} CCO2</Typography>
          <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Container className="search-form">
      <Form noValidate validated={validated} ref={form} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="document" className="form-group">
            <h6 htmlFor="document">Consultar Compensação</h6>
            <InputMask
              id="value"
              mask={documentType === 'CPF' ? "999.999.999-99" : "99.999.999/9999-99"}
              value={document}
              onChange={handleDocumentChange}
              onBlur={() => setDocumentTouched(true)} // Mark as touched on blur
              required
              name="documentInput"
              type="text"
              placeholder={documentType === 'CPF' ? "CPF" : "CNPJ"}
              className={`form-control ${documentTouched && documentError ? 'is-invalid' : ''} ${documentTouched && !documentError && document ? 'is-valid' : ''}`}
            />
            {documentError && (
              <div className="invalid-feedback" style={{display: 'block'}}>
                {documentError}
              </div>
            )}
            <div style={{marginTop: 10}}>
              <Form.Check
                inline
                type="radio"
                label="CPF"
                name="documentType"
                value="CPF"
                checked={documentType === 'CPF'}
                onChange={handleTypeChange}
              />
              <Form.Check
                inline
                type="radio"
                label="CNPJ"
                name="documentType"
                value="CNPJ"
                checked={documentType === 'CNPJ'}
                onChange={handleTypeChange}
              />
            </div>
          </Form.Group>
        </Row>
        <button type="submit" className="btn-custom float-end" disabled={isLoading}>
          Consultar
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
            hidden={!isLoading}
          />
        </button>
      </Form>
      <Dialog open={openDialog} onClose={handleClose} PaperProps={{sx: {borderRadius: 4}}}>
        <DialogTitle>Compensações</DialogTitle>
        <DialogContent>{compensations && renderCertificationDetails(compensations)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer/>
    </Container>
  );
}

export default CompensationSearchForm;
