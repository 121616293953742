import { useRef} from "react";

export const LearnMore = (props) => {

  const ref = useRef([]);
  const handler = (idx) => {
    const next = ref.current[idx];
    if (next) {
      setTimeout(function () {
        next.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }

  return (
    <div id="learn-more">
      <div id="learn-more-anchor" className="anchor"></div>
      <div className="container">
        <div className="row text-center">
          <h2 className="centered-title">{props.data ? props.data.title : "loading..."}</h2>
        </div>
        <div className="accordion" id="learn-more-accordion">
          <div className="accordion-item">
            <div key={0} ref={el => ref.current[0] = el} className="anchor"></div>
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"
                      onClick={() => handler(0)}>
                <h3>{props.data ? props.data.challenges.title : "loading..."}</h3>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                 data-bs-parent="#learn-more-accordion">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-lg-6 text-center">
                    <img src="/assets/img/home/pictures/learnMoreChallenges01.jpg" className="img-fluid" alt=""/>
                  </div>
                  <div className="col-lg-6">
                    <p>{props.data ? props.data.challenges.paragraph1 : "loading..."}</p>
                    <p>{props.data ? props.data.challenges.paragraph2 : "loading..."}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h3>{props.data ? props.data.challenges.subtitle1 : "loading..."}</h3>
                    <p>{props.data ? props.data.challenges.paragraph3 : "loading..."}</p>
                    <p>{props.data ? props.data.challenges.paragraph4 : "loading..."}</p>
                  </div>
                  <div className="col-lg-6">
                    <img src="/assets/img/home/pictures/learnMoreChallenges02.jpg" className="img-fluid" alt=""/>
                  </div>
                  <div className="col-lg-12">
                    <p>{props.data ? props.data.challenges.paragraph5 : "loading..."}</p>
                    <p>{props.data ? props.data.challenges.paragraph6 : "loading..."}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <img src="/assets/img/home/pictures/learnMoreChallenges03.jpg" className="img-fluid" alt=""/>
                  </div>
                  <div className="col-lg-6">
                    <h3>{props.data ? props.data.challenges.subtitle2 : "loading..."}</h3>
                    <p>{props.data ? props.data.challenges.paragraph7 : "loading..."}</p>
                    <p>{props.data ? props.data.challenges.paragraph8 : "loading..."}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div key={1} ref={el => ref.current[1] = el} className="anchor"></div>
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                      onClick={() => handler(1)}>
                <h3>{props.data ? props.data.markets.title : "loading..."}</h3>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                 data-bs-parent="#learn-more-accordion">
              <div className="accordion-body">
                <div className="d-none d-xl-block">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets01.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle1 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph2 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle2 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph4 : "loading..."}</p>
                    </div>
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets02.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-6">
                    <img src="/assets/img/home/pictures/learnMoreMarkets03.jpg" className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6">
                    <h3>{props.data ? props.data.markets.subtitle3 : "loading..."}</h3>
                    <p>{props.data ? props.data.markets.paragraph5 : "loading..."}</p>
                    <p>{props.data ? props.data.markets.paragraph6 : "loading..."}</p>
                  </div>
                </div>
                </div>
                <div className="d-none d-lg-block d-xl-none">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets01.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle1 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph1 : "loading..."}</p>
                    </div>
                    <div className="col-lg-12">
                      <p>{props.data ? props.data.markets.paragraph2 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle2 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph3 : "loading..."}</p>
                    </div>
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets02.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-12">
                      <p>{props.data ? props.data.markets.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets03.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle3 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph5 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph6 : "loading..."}</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none text-center">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets01.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle1 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph2 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets02.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle2 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="/assets/img/home/pictures/learnMoreMarkets03.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <h3>{props.data ? props.data.markets.subtitle3 : "loading..."}</h3>
                      <p>{props.data ? props.data.markets.paragraph5 : "loading..."}</p>
                      <p>{props.data ? props.data.markets.paragraph6 : "loading..."}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <div className="accordion-item">
            <div key={2} ref={el => ref.current[2] = el} className="anchor"></div>
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                      onClick={() => handler(2)}>
                <h3>{props.data ? props.data.carbon.title : "loading..."}</h3>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                 data-bs-parent="#learn-more-accordion">
              <div className="accordion-body text-justify">
                <div className="d-none d-xl-block">
                  <div className="row">
                    <div className="col-6 text-center">
                      <img src="/assets/img/home/pictures/learnMoreCarbon01.jpg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-6">
                      <p>{props.data ? props.data.carbon.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph2 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-block d-xl-none">
                  <div className="row">
                    <div className="col-6 text-center">
                      <img src="/assets/img/home/pictures/learnMoreCarbon01.jpg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-6">
                      <p>{props.data ? props.data.carbon.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph2 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph3 : "loading..."}</p>
                    </div>
                    <div className="col-12">
                      <p>{props.data ? props.data.carbon.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none text-center">
                  <div className="row">
                    <div className="col-12 text-center">
                      <img src="/assets/img/home/pictures/learnMoreCarbon01.jpg" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-12">
                      <p>{props.data ? props.data.carbon.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph2 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.carbon.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <div key={3} ref={el => ref.current[3] = el} className="anchor"></div>
            <h2 className="accordion-header" id="headingFour">
              <button className="accordion-button shadow-none collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                      onClick={() => handler(3)}>
                <h3>{props.data ? props.data.methane.title : "loading..."}</h3>
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                 data-bs-parent="#learn-more-accordion">
              <div className="accordion-body text-justify">
                <div className="d-none d-xl-block">
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <img src="/assets/img/home/pictures/learnMoreMethane01.jpg" className="img-fluid img-left"
                           alt=""/>{" "}
                    </div>
                    <div className="col-6">
                      <p>{props.data ? props.data.methane.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph2 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-block d-xl-none">
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <img src="/assets/img/home/pictures/learnMoreMethane01.jpg" className="img-fluid img-left"
                           alt=""/>{" "}
                    </div>
                    <div className="col-6">
                      <p>{props.data ? props.data.methane.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph2 : "loading..."}</p>
                    </div>
                    <div className="col-12">
                      <p>{props.data ? props.data.methane.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none text-center">
                  <div className="row">
                    <div className="col-12">
                      {" "}
                      <img src="/assets/img/home/pictures/learnMoreMethane01.jpg" className="img-fluid img-left"
                           alt=""/>{" "}
                    </div>
                    <div className="col-12">
                      <p>{props.data ? props.data.methane.paragraph1 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph2 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph3 : "loading..."}</p>
                      <p>{props.data ? props.data.methane.paragraph4 : "loading..."}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
