import "./App.scss";

import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/layout";
import Home from "./components/pages/home/home";
import Certifications from "./components/pages/certifications/certifications";
import Conference from "./components/pages/conference/conference";
import Enroll from "./components/pages/enroll/enroll";
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="certificados" element={<Certifications/>}/>
          <Route path="conferencia" element={<Conference/>}/>
          <Route path="associe-se" element={<Enroll/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App/>, document.getElementById("root"));
