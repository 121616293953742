import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EnrollForm from "./enroll-form";

export const EnrollContact = (props) => {
  return (
    <div>
      <div id='enroll-contact'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7'>
              <div className='row'>
                <div className='section-title'>
                  <h2>Associe-se</h2>
                  <p>
                    Por favor, preencha o formulário abaixo para nos enviar uma solicitação de associação.
                    Iremos lhe responder assim que possível.
                  </p>
                </div>
                <EnrollForm></EnrollForm>
              </div>
            </div>
            <div className='col-md-4 offset-md-1'>
              <div className='contact-item center-text-md'>
                <h3>Informações de Contato</h3>
                <p className='center-text-md'>
                  <span>
                    <i className='fa fa-map-marker'></i> Endereço
                  </span>
                </p>
                <p className='center-text-md'>
                  {props.data ? props.data.address1 : 'loading'}
                </p>
                <p className='center-text-md'>
                  {props.data ? props.data.address2 : 'loading'}
                </p>
                <p className='center-text-md'>
                  {props.data ? props.data.address3 : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p className='center-text-md'>
                  <span>
                    <i className='fa fa-envelope-o'></i> E-mail
                  </span>{' '}
                  {props.data ? props.data.email : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p className='center-text-md'>
                  <span>
                    <i className='fa fa-whatsapp'></i> WhatsApp
                  </span>{' '}
                  {props.data ? props.data.whatsapp : 'loading'}
                </p>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row'>
                <div className='social'>
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : '/'} target="_blank" rel="noreferrer">
                        <i className='fa fa-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.instagram : '/'} target="_blank" rel="noreferrer">
                        <i className='fa fa-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.linkedin : '/'} target="_blank" rel="noreferrer">
                        <i className='fa fa-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022-2024 abcarbon
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
