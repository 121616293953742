import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import {Card, Collapse, FormControlLabel, Switch} from "@mui/material";
import {Container} from "react-bootstrap";
import "./certifications.scss";
import CertificationSearchForm from "./certification-search-form";
import CertificationsList from "./certifications-list";
import Paper from "@mui/material/Paper";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CompensationSearchForm from "./compensation-search-form";

export default function Certifications() {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    document.body.classList.add('certifications-background');

    return () => {
      document.body.classList.remove('certifications-background');
    };
  }, []);

  useEffect(() => {
    const buttonElement = document.getElementById('whatsAppButton');
    if (buttonElement) {
      buttonElement.style.display = 'unset';
    }
  }, []);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div id="certifications" className="content-wrapper">
      <div className="content">
        <Container id="certifications-container" className="mt-5">
          <Box className="pt-3 mx-2">
            <Paper className="search-wrapper my-2" elevation={2}>
              <p className="px-3 py-2 m-0 search-form-title">
                Consultas
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleChange}/>}
                  className="ms-2"
                />
              </p>
              <Collapse in={checked}>
                <Row className="mb-3 mx-2">
                  <Col md="6">
                    <Card>
                      <CertificationSearchForm />
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CompensationSearchForm />
                    </Card>
                  </Col>
                </Row>
              </Collapse>
            </Paper>
            <div className={checked ? 'low-brightness' : ''}>
              <Collapse in={!checked} className="pb-4" collapsedSize={300}>
                <p className="px-3 my-2 m-0 search-form-title">
                  Lista de Certificados
                </p>
                <CertificationsList />
              </Collapse>
            </div>
          </Box>
        </Container>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p className="copyright">
            &copy; 2022-2024 abcarbon
          </p>
        </div>
      </div>
    </div>
  );
}
