import {useCallback, useRef, useState} from 'react';
import {Container, Modal, Spinner} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {storage} from "../../../config/firebase";
import {getDownloadURL, ref} from "firebase/storage";
import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import {toast, ToastContainer} from "react-toastify";
import "./search-form.scss";

function CertificationSearchForm() {
  const [validated, setValidated] = useState(false);
  const [certKeyError, setCertKeyError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);

  const form = useRef();
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
      if (form.certKey.validity.valueMissing) {
        setCertKeyError('Campo obrigatório.');
      }
      if (form.certKey.validity.tooShort || form.certKey.validity.tooLong) {
        setCertKeyError('A chave deve conter 32 caracteres.');
      }
    } else {
      fetchCertificationById(form.certKey.value);
    }
  };

  function fetchCertificationById(certificationId: string) {
    setIsLoading(true);
    const imageRef = ref(storage, 'certifications/' + certificationId + '.jpg');
    getDownloadURL(imageRef)
      .then((url) => {
        setImageUrl(url);
        setShowModal(true);
        setIsLoading(false);
        setValidated(false);
      })
      .catch((error) => {
        toast.error('Certificado não encontrado.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        // console.error(error);
        setIsLoading(false);
        setValidated(false);
      });
  }

  return (
    <Container className="search-form">
      <Form noValidate validated={validated} ref={form} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="certKey" className="form-group">
            <h6 htmlFor="certKey">Consultar Certificado</h6>
            <Form.Control
              required
              name="certKey"
              type="text"
              minLength={32}
              maxLength={32}
              placeholder="Código do Certificado (certification code)"
            />
            <Form.Control.Feedback type="invalid">
              {certKeyError}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <button type="submit" className="btn-custom float-end" disabled={isLoading}>
          Consultar
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
            hidden={!isLoading}
          />
        </button>
      </Form>
      <Modal id="preview-modal" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <h4>Visualização Certificado</h4>
        </Modal.Header>
        <Modal.Body>
          <QuickPinchZoom onUpdate={onUpdate}>
            <img ref={imgRef} className="certification-preview-img" src={imageUrl} alt="Preview" />
          </QuickPinchZoom>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </Container>
  );

}

export default CertificationSearchForm;
