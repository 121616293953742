export const Credits = (props) => {
  return (
    <div id="credits">
      <div id="credits-anchor" className="anchor"></div>
      <div className="container">
        <div className="d-none d-lg-block">
          <div className="row">
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits01.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <div className="about-text">
                <h2>{props.data ? props.data.title : "loading..."}</h2>
                <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="about-text">
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits02.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div className="d-none d-md-block d-lg-none">
          <div className="row">
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits01.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <div className="about-text">
                <h2>{props.data ? props.data.title : "loading..."}</h2>
                <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="about-text">
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits02.jpg" className="img-fluid" alt="" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none text-center">
          <div className="row">
            <h2 className="centered-title">{props.data ? props.data.title : "loading..."}</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits01.jpg" className="img-fluid" alt="" />
              <div className="about-text">
                <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/assets/img/home/pictures/credits02.jpg" className="img-fluid" alt="" />
              <div className="about-text">
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
