import React, {useRef, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Container, Spinner} from "react-bootstrap";
import emailjs from '@emailjs/browser';
import {toast} from "react-toastify";

function ContactForm() {
  const [validated, setValidated] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    return emailjs
      .sendForm(
        'service_ktuor1i', 'template_e0pv72v', form.current, '57QbxsmAbWUnniJb1'
      )
      .then(
        (result) => {
          form.current.reset();
          toast.success('Mensagem enviada com sucesso!', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          setIsLoading(false);
          setValidated(false);
        },
        (error) => {
          toast.error('Um erro inesperado ocorreu. Tente novamente mais tarde, ou entre em contato pelo e-mail ao lado.', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          setIsLoading(false);
          setValidated(false);
        }
      );
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      if (form.email.validity.valueMissing) {
        setEmailError('Campo obrigatório.');
      }
      if (form.email.validity.typeMismatch) {
        setEmailError('Por favor, insira um e-mail válido.');
      }
    } else {
      sendEmail(event);
    }
  };

  return (
    <Container className="form-wrapper">
      <Form noValidate validated={validated} ref={form} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="name" className="form-group">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder="Nome"
            />
            <Form.Control.Feedback type="invalid">
              Campo obrigatório.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="email" className="form-group">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              name="email"
              type="email"
              placeholder="E-mail"
            />
            <Form.Control.Feedback type="invalid">
              { emailError }
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group as={Col} md="12" controlId="message" className="form-group mb-3">
          <Form.Control
            required
            rows="4"
            name="message"
            as="textarea"
            placeholder="Mensagem"
          />
          <Form.Control.Feedback type="invalid">
            Campo obrigatório.
          </Form.Control.Feedback>
        </Form.Group>
        <button type="submit" className="btn-custom" disabled={isLoading}>
          Enviar
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
            hidden={!isLoading}
          />
        </button>
      </Form>
    </Container>
  );
}

export default ContactForm;
