export const Leadership = (props) => {
  return (
    <div id="leadership" className='text-center'>
      <div className="container">
        <h2>{props.data ? props.data.title : "loading..."}</h2>
        <div className="row pt-4">
          <div className="card-wrapper col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <div className='thumbnail'>
                  <img src={props.data ? props.data.eduardo.img : 'loading...'} alt='...' />
                </div>
                <h3>{props.data ? props.data.eduardo.title : 'loading...'}</h3>
                <p className="name">{props.data ? props.data.eduardo.name : 'loading...'}</p>
                <p className="title">{props.data ? props.data.eduardo.title : 'loading...'}</p>
                <p className="saying">{props.data ? props.data.eduardo.text : 'loading...'}</p>
              </div>
            </div>
          </div>
          <div className="card-wrapper  col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <div className='thumbnail'>
                  <img src={props.data ? props.data.sebastiao.img : 'loading...'} alt='...' />
                </div>
                <h3>{props.data ? props.data.sebastiao.title : 'loading...'}</h3>
                <p className="name">{props.data ? props.data.sebastiao.name : 'loading...'}</p>
                <p className="title">{props.data ? props.data.sebastiao.title : 'loading...'}</p>
                <p className="saying">{props.data ? props.data.sebastiao.text : 'loading...'}</p>
              </div>
            </div>
          </div>
          <div className="card-wrapper col-sm-12 col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <div className='thumbnail'>
                  <img src={props.data ? props.data.ramiro.img : 'loading...'} alt='...' />
                </div>
                <h3>{props.data ? props.data.ramiro.title : 'loading...'}</h3>
                <p className="name">{props.data ? props.data.ramiro.name : 'loading...'}</p>
                <p className="title">{props.data ? props.data.ramiro.title : 'loading...'}</p>
                <p className="saying">{props.data ? props.data.ramiro.text : 'loading...'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
