import React, {useEffect, useState} from "react";
import {ConferenceMenu} from "./conference-menu";
import {Header} from "./header";
import {Intro} from "./intro";
import {Target} from "./target";
import {Themes} from "./themes";
import {Attendance} from "./attendance";
import {Leadership} from "./leadership";
import {Footer} from "./footer";
import {Itinerary} from "./itinerary";
import JsonData from "../../../data/conference.json";
import {scrollSpy} from "react-scroll";
import "./conference.scss";

export const Conference = (props) => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    scrollSpy.update()
    window.addEventListener('resize', scrollSpy.update)
    return () => {
      window.removeEventListener('resize', scrollSpy.update)
    }
  }, []);

  useEffect(() => {
    const buttonElement = document.getElementById('whatsAppButton');
    if (buttonElement) {
      buttonElement.style.display = 'none';
    }
  }, []);

  return (
    <div id="conference">
      <ConferenceMenu/>
      <Header data={landingPageData.Header}/>
      <Intro data={landingPageData.Intro}/>
      <Themes data={landingPageData.Themes}/>
      <Leadership data={landingPageData.Leadership}/>
      <Target data={landingPageData.Target}/>
      <Attendance data={landingPageData.Attendance}></Attendance>
      {/*<MapContainer data={landingPageData.Speakers}></MapContainer>*/}
      <Itinerary data={landingPageData.Itinerary}/>
      <Footer data={landingPageData.Footer}/>
      <div className='container text-center'>
        <p className="copyright">
          &copy; 2022-2024 abcarbon
        </p>
      </div>
    </div>
  );
}

export default Conference;
