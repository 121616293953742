export const Attendance = (props) => {
  return (
    <div id='attendance' className='text-center'>
      <div className='container'>
        <h2>{props.data ? props.data.title : "loading..."}</h2>
        <div id="content" className='row'>
          {props.data
            ? props.data.presences.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-xs-12 col-sm-6 col-lg-4 content-card'>
                <div className='thumbnail'>
                  <img src={d.img} alt='...' className='team-img' />
                  {
                    d.name != null &&
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.title}</p>
                      { i !== 0 && <div className='space'></div> }
                      <p>{d.desc}</p>
                    </div>
                  }
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
      <div className="divider"></div>
      <div className='container'>
        <h2>{props.data ? props.data.title2 : "loading..."}</h2>
        <div id="content" className='row'>
          {props.data
            ? props.data.support.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-xs-12 col-sm-6 col-lg-3 content-card'>
                <div className='thumbnail'>
                  <img src={d.img} alt='...' className='team-img' />
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
      <div className="divider"></div>
      <div className='container'>
        <h2>{props.data ? props.data.title3 : "loading..."}</h2>
        <div id="content" className='row'>
          {props.data
            ? props.data.execution.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-4 offset-4 content-card'>
                <div className='thumbnail'>
                  <img src={d.img} alt='...' className='team-img' />
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
};
